<template>
  <div class="activation">
    <div class="content">
      <img src="../../assets/html/thanksImg.png" alt="" />
      <p class="tit">感谢您的反馈</p>
      <el-button type="primary" @click="goUpdateResume"> 更新简历</el-button>
      <p class="tips">
        <span>如合适3天内会有猎头顾问联系您</span>
        <span> 为您提供一对一求职服务 </span>
      </p>
    </div>
  </div>
</template>

<script>
import {
  candidateOperateInviteFeedback, //职位邀请反馈
} from "../../api/html";
export default {
  data() {
    return {
      pid: "",
      rid: "",
      result: "",
    };
  },
  created() {
    //yd-uat.casearching.com/invite.html?pid=YcEzwIB3t+Y=&rid=cyMQ8hBtQnE=&result=INTEREST
    this.pid = this.$route.query.pid;
    this.rid = this.$route.query.rid;
    this.result = this.$route.query.result;
    // console.log(
    //     this.$route.query.pid
    // )
    // resetRem();
    this.feedback();
  },
  methods: {
    //反馈
    feedback() {
      candidateOperateInviteFeedback({
        pid: this.pid,
        rid: this.rid,
        result: this.result,
      }).then((res) => {
        if (res.success) {
          console.log(res);
        }
      });
    },
    goUpdateResume() {
      this.$router.push({
        path: "/updateResume",
        query: {
          rid: this.rid,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.activation {
  max-width: 750px;
  background: #ffffff;
  min-height: 100vh;
  margin: 0 auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .content {
    max-width: 750px;
    margin: 0 auto;
    padding: 120px 80px;
    text-align: center;
    img {
      width: 100px;
      height: 100px;
    }
    .tit {
      font-size: 24px;
      margin: 2% 0 10%;
      font-weight: 500;
    }
    .el-button {
      width: 100%;
      font-size: 16px;
      font-weight: 500;
      border-radius: 4px;
      margin: 40px auto 20px;
    }

    .tips {
      color: #999;
      font-size: 14px;
      text-align: center;
      position: absolute;
      bottom: 80px;
      left: 50%;
      margin-left: -101.4px;
      span {
        display: block;
        line-height: 1.5;
      }
    }
  }
}
</style>
